/* global WPURLS */
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
import '../blocks/gutenberg/acf-abaut-hero-block/index.js'; import '../blocks/gutenberg/acf-example-block/index.js'; import '../blocks/gutenberg/acf-hero-slider/index.js'; import '../blocks/gutenberg/acf-home-cta/index.js'; import '../blocks/gutenberg/acf-home-videos-block/index.js'; import '../blocks/gutenberg/acf-text-years-block/index.js'; import '../blocks/gutenberg/acf-title-image-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */

//threesixty init
let url = WPURLS.siteurl;
let postId = WPURLS.post_id;

$(document).on('ready', function () {
  var countValue = parseFloat($('.count-360').text());

  if ($('.product-slider').length) {
    let product360 = $('.product-slider').ThreeSixty({
      totalFrames: countValue,
      endFrame: countValue,
      currentFrame: Math.min(8, countValue),
      imgList: '.threesixty_images',
      progress: '.spinner',
      imagePath: url + '/wp-content/uploads/products/' + postId + '/',
      filePrefix: '',
      ext: '.png',
      width: 100 + '%',
      height: 600,
      navigation: false,
      fullscreen: false,
      fSBackgroundColor: '#000',
      responsive: false,
      disableSpin: false,
      autoplayDirection: 1,
      zeroPadding: true,
      onReady: function () {
        $('.slider-preview').addClass('preview-hide');
        $('.product-slider').addClass('product-slider-show');
      },
    });

    $('.custom-play').on('click touch', function () {
      product360.play();
      $(this).addClass('play-hide');
      $('.custom-stop').addClass('stop-show');
    });

    $('.custom-stop').on('click touch', function () {
      product360.stop();
      $('.custom-play').removeClass('play-hide');
      $(this).removeClass('stop-show');
    });

    $('.custom-next').on('click touch', function () {
      product360.next();
    });

    $('.custom-back').on('click touch', function () {
      product360.previous();
    });
  }

  //single Hero section
  $('.tab-head-slider-item').on('click', function () {
    $('.product-hero__image-content-item').removeClass('active-image');
    $('.product-slider').show();
    $(
      '.product-hero__video-content .product-hero__video-content-item'
    ).removeClass('active-video');
  });

  $('.tab-video-items .tab-head-images-item').on('click', function () {
    let tab = $(this).attr('video-tab');
    let block = $(this).parents('.product-hero');
    let content = block.find('.product-hero__video-content-item');
    content.each(function () {
      $(this).removeClass('active-video');
      if (tab == $(this).attr('data-video')) {
        $(this).addClass('active-video');
      }
    });
    $('.product-slider').hide();
    $('.product-hero__image-content-item').removeClass('active-image');
  });

  $('.tab-head-images .tab-head-images-item').on('click', function () {
    let tab = $(this).attr('data-image-tab');
    let block = $(this).parents('.product-hero');
    let content = block.find('.product-hero__image-content-item');
    content.each(function () {
      $(this).removeClass('active-image');
      if (tab == $(this).attr('data-content')) {
        $(this).addClass('active-image');
      }
    });
    $('.product-slider').hide();
    $(
      '.product-hero__video-content .product-hero__video-content-item'
    ).removeClass('active-video');
  });

  if ($('.youtube-wrap, .vimeo-wrap').length) {
    $('.play-button').on('click', function () {
      let block = $(this).parents('.product-hero__video-content-item');
      let item = block.find('iframe');
      item.each(function () {
        $(this).parents('.preview-image').addClass('hide-preview');
        item[0].src += '&autoplay=1';
      });
    });
  }

  if ($('.media-video').length) {
    $('.play-button').on('click', function () {
      $(this).parents('.preview-image').addClass('hide-preview');
      let block = $(this)
        .parents('.product-hero__video-content-item')
        .find('.media-video');
      block[0].play();
    });
  }

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
