import $ from 'jquery';
$(document).ready(function () {
  heroSliderBlock();
});
function heroSliderBlock() {
  $('.js-acf-hero-slider-block').each(function () {
    $('.hero-slider').slick({
      // cssEase: 'ease',
      // fade: true,
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: false,
      pauseOnHover: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: '.nav-slider',
    });
    $('.nav-slider').slick({
      slidesToShow: 7,
      slidesToScroll: 1,
      arrows: false,
      asNavFor: '.hero-slider',
      dots: false,
      infinite: false,
      focusOnSelect: true,
      autoplay: false,
    });

    let item = $('.nav-slider').find('.slick-slide');
    item.mouseenter(handlerIn);
    item.mouseleave(handlerOut);
    function handlerIn() {
      $(this).addClass('nav-hover');
    }
    function handlerOut() {
      $(this).removeClass('nav-hover');
    }
  });
}
